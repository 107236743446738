import { IonButtons, IonCol, IonGrid, IonMenuButton, IonRow } from '@ionic/react'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { useAppSelector } from '../../store/hooks'
import { HeaderDashboard } from '../Dashboard/components/HeaderDashboard'
import { SectionSelectCustomer } from '../Dashboard/components/SectionSelectCustomer/SectionSelectCustomer'
import { CartTotal } from './components/CartTotal/CartTotal'
import { FooterPos } from './components/FooterPos/FooterPos'
import { GridDashboardPos } from './components/GridDashboardPos/GridDashboardPos'
import { ListProductCartPos } from './components/ListProductCartPos/ListProductCartPos'
import { Library } from './components/Library/Library'
import { Calculator } from '../Dashboard/components/Calculator'

export interface ServicesPosType {
  id: number
  title: string
  subtitle?: string
  image: string | null
  icon?: string
  price: number
  code: string
}

export interface ProductCart extends ServicesPosType {
  quantity?: number
}

export type ServicePos = ServicesPosType | null

interface PropsRowGridData {
  gridServices: ServicePos[] | null[]
  productsCart: ProductCart[]
}

function RowGridData ({ productsCart, gridServices }: PropsRowGridData) {
  const posState = useAppSelector(store => store.pos)

  return (
    <IonRow>
      <IonCol size="12" size-md="6" size-xl="7">
        <GridDashboardPos
          gridServices={gridServices}
          productsCart={productsCart}
        />
      </IonCol>
      {
      !posState.showCalculator &&
        <IonCol size="12" size-md="6" size-xl="5">
          <SectionSelectCustomer />
          <ListProductCartPos
            productsCart={productsCart}
          />
        {productsCart?.length > 0 && !posState.showCalculator && <CartTotal productsCart={productsCart}/>}
        </IonCol>
      }
      {
        posState.showCalculator &&
        <IonCol size="12" size-md="6" size-xl="5">
          <Calculator />
        </IonCol>
      }
    </IonRow>
  )
}

export function Pos () {
  const posState = useAppSelector(store => store.pos)
  const accountOne = posState.accountOne
  const accountTwo = posState.accountTwo
  const accountThree = posState.accountThree

  console.log({ posState })
  return (
    <>
      <WrapperApp
        pageName="Punto de venta"
        hiddenAvatar={true}
        renderButtons={() => (
          <>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <HeaderDashboard />
          </>
        )}
        renderFooter={() => (
          <FooterPos />
        )}
      >
        <IonGrid>
         {
          posState.tab === 'accountOne' &&
            <RowGridData
              gridServices={posState.gridServices}
              productsCart={accountOne.productsCart}
            />
         }
         {
          posState.tab === 'accountTwo' &&
            <RowGridData
              gridServices={posState.gridServices}
              productsCart={accountTwo.productsCart}
            />
         }
         {
          posState.tab === 'accountThree' &&
            <RowGridData
              gridServices={posState.gridServices}
              productsCart={accountThree.productsCart}
            />
         }
         {
          posState.tab === 'library' &&
            <Library />
         }
        </IonGrid>
      </WrapperApp>
    </>
  )
}
