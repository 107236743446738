import { configureStore } from '@reduxjs/toolkit'
import type { Commerce } from '../models/commerce'
import { type RoleUserActive } from '../models/role-user-active'
import type { User } from '../models/user'
import type { UserBanks } from '../models/user-banks'
import type { UserCardBank } from '../models/user-card-bank'
import type { UserPreference } from '../models/user-preference'
import type { UserSegurity } from '../models/user-segurity'
import { commerceSlice } from './states/commerce'
import { roleUserActiveSlice } from './states/roleUserActive'
import { userSlice } from './states/user'
import { userBanksSlice } from './states/userBanks'
import { userCardsBankSlice } from './states/userCardsBank'
import { userPreferenceSlice } from './states/userPreference'
import { userSeguritySlice } from './states/userSegurity'
import { userThemeSlice } from './states/userTheme'
import { toggleMenuSlice } from './states/toggleMenu'
import { posSlice, type PosStateType } from './states/pos'

export interface AppStore {
  user: User
  commerce: Commerce
  userPreference: UserPreference
  userSegurity: UserSegurity
  userBanks: UserBanks
  userCardsBank: UserCardBank
  roleUserActive: RoleUserActive
  userDarkmode: boolean
  toggleMenu: boolean
  pos: PosStateType
}

export const store = configureStore<AppStore>({
  reducer: {
    user: userSlice.reducer,
    commerce: commerceSlice.reducer,
    userPreference: userPreferenceSlice.reducer,
    userSegurity: userSeguritySlice.reducer,
    userBanks: userBanksSlice.reducer,
    userCardsBank: userCardsBankSlice.reducer,
    roleUserActive: roleUserActiveSlice.reducer,
    userDarkmode: userThemeSlice.reducer,
    toggleMenu: toggleMenuSlice.reducer,
    pos: posSlice.reducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
