import { IonButton, IonIcon, IonItem, useIonToast } from '@ionic/react'
import { cash, closeOutline, qrCode } from 'ionicons/icons'
import { LineSeparator } from '../../../../components/Atoms/LineSeparator/LineSeparator'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import { ModalInstacash } from '../ModalInstacash/ModalInstacash'
import { useState } from 'react'
import { ModalQr } from '../ModalQr/ModalQr'
import { useAppSelector } from '../../../../store/hooks'

interface Props {
  seIisOpenModalSelectPaymentMethod: React.Dispatch<React.SetStateAction<boolean>>
  totalSumProducts: number
}

export function ModalSelectPaymentMethod ({ seIisOpenModalSelectPaymentMethod, totalSumProducts }: Props) {
  const [present] = useIonToast()
  const [isOpenModalInstacash, setIsOpenModalInstacash] = useState(false)
  const [isOpenModalQr, setIsOpenModalQr] = useState(false)
  const userPreference = useAppSelector(store => store.userPreference)

  const handleModalInstacash = () => {
    if (!userPreference.preferredChannelOfUse.instaCash) {
      present({
        duration: 2500,
        message: 'Canal de pago desactivado. puede activalo en: configuración > preferencia > canal de uso preferido',
        position: 'bottom',
        color: 'danger'
      })
      return
    }

    setIsOpenModalInstacash(true)
  }

  const handleModalQr = () => {
    if (!userPreference.preferredChannelOfUse.qr) {
      present({
        duration: 2500,
        message: 'Canal de pago desactivado. puede activalo en: configuración > preferencia > canal de uso preferido',
        position: 'bottom',
        color: 'danger'
      })
      return
    }

    setIsOpenModalQr(true)
  }

  return (
    <>
      <IonItem className='ion-no-padding ion-no-margin' lines='none'>
        <TitleForm title="Efectivo" customClass="ion-no-padding ion-no-margin" />
        <IonIcon icon={closeOutline} size='large' slot='end' onClick={() => { seIisOpenModalSelectPaymentMethod(false) }} />
      </IonItem>
      <LineSeparator noSpaceTop />
      <div style={{ width: '100%', gap: '.5rem' }} className="flex ion-justify-content-between ion-align-items-center ion-margin-bottom ion-wrap">
        <IonButton color="primary" shape="round" type="button">8.78</IonButton>
        <IonButton color="primary" shape="round" type="button">9</IonButton>
        <IonButton color="primary" shape="round" type="button">10</IonButton>
        <IonButton color="primary" shape="round" type="button">20</IonButton>
        <IonButton color="primary" shape="round" type="button">Otro</IonButton>
      </div>
      <IonItem className='ion-no-padding ion-no-margin' lines='none'>
        <TitleForm title="Selecciona un canal de pago" customClass="ion-no-padding ion-no-margin" />
      </IonItem>
      <LineSeparator noSpaceTop />
      <div className='ion-text-start'>
        <div onClick={() => { handleModalInstacash() }} style={{ zIndex: 500 }}>
          <IonButton disabled={!userPreference.preferredChannelOfUse.instaCash} color="secondary" shape="round" type="button" expand="block" className='ion-margin-bottom'>
            <IonIcon slot="start" icon={cash}></IonIcon>
            Instacash
          </IonButton>
        </div>
        <IonButton disabled={!userPreference.preferredChannelOfUse.nfc} color="secondary" shape="round" type="button" expand="block" className='ion-margin-bottom'>
          <IonIcon slot="start" icon={cash}></IonIcon>
          NFC
        </IonButton>
        <div onClick={() => { handleModalQr() }} style={{ zIndex: 1000 }}>
          <IonButton disabled={!userPreference.preferredChannelOfUse.nfc} color="secondary" shape="round" type="button" expand="block">
            <IonIcon slot="start" icon={qrCode}></IonIcon>
            QR
          </IonButton>
        </div>
      </div>
      <ModalSmall isOpen={isOpenModalInstacash} onDidDismiss={() => { setIsOpenModalInstacash(false) }}>
        <ModalInstacash
          setIsOpenModalInstacash={setIsOpenModalInstacash}
          seIisOpenModalSelectPaymentMethod={seIisOpenModalSelectPaymentMethod}
          totalSumProducts={totalSumProducts}
        />
      </ModalSmall>
      <ModalSmall isOpen={isOpenModalQr} onDidDismiss={() => { setIsOpenModalQr(false) }}>
        <ModalQr
          setIsOpenModalQr={setIsOpenModalQr}
          seIisOpenModalSelectPaymentMethod={seIisOpenModalSelectPaymentMethod}
          totalSumProducts={totalSumProducts}
        />
      </ModalSmall>
    </>
  )
}
