import React from 'react'
import { IonLabel, IonCard, IonList, IonItem, IonIcon } from '@ionic/react'
import { type DataUserType } from '../../../models'

interface ListOfDataProps {
  dataList: DataUserType[]
  children?: React.ReactNode
}

export const ListOfData: React.FC<ListOfDataProps> = ({ dataList, children }) => {
  return (
    <IonCard>
      <IonList>
        {dataList.map((item, index) =>
          <IonItem routerLink={`/${item.typeProfile}/${item.routerLink}`} key={index} button detail={!item?.disabled} disabled={item?.disabled}>
            <IonIcon icon={item.icon} slot="start"></IonIcon>
            <IonLabel>
              <h2>{item.title}</h2>
              {item.result && <p>{item.result}</p>}
            </IonLabel>
          </IonItem>
        )}
        { children }
      </IonList>
    </IonCard>
  )
}
