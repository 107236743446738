import { FormLogin } from './components/FormLogin/FormLogin'
import WrapperFormBackground from '../../components/Organisms/WrapperFormBackground/WrapperFormBackground'

export const Login = () => {
  return (
    <WrapperFormBackground title="Login">
      <FormLogin />
    </WrapperFormBackground>
  )
}

export default Login
