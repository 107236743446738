import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { getLocalStorage, setLocalStorage } from '../../utilities/localStorage.utility'

const initialState = false

export const toggleMenuSlice = createSlice({
  name: 'is-menu-small',
  initialState: getLocalStorage('is-menu-small') ? JSON.parse(getLocalStorage('is-menu-small') as string) : initialState,
  reducers: {
    changeToggleMenu (_state, action: PayloadAction<boolean>) {
      setLocalStorage('is-menu-small', action.payload)
      return action.payload
    }
  }
})

export const {
  changeToggleMenu
} = toggleMenuSlice.actions
export default toggleMenuSlice.reducer
