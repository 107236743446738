import { IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react'
import { copy, sync } from 'ionicons/icons'
import { useState } from 'react'
import { LineSeparator } from '../../../../components/Atoms/LineSeparator/LineSeparator'
import { ModalPaymentSuccess } from '../ModalPaymentSuccess/ModalPaymentSuccess'
import { ModalPaymentError } from '../ModalPaymentError/ModalPaymentError'
import { CountDown } from '../CountDown/CountDown'

interface Props {
  setIsOpenModalInstacash: React.Dispatch<React.SetStateAction<boolean>>
  seIisOpenModalSelectPaymentMethod: React.Dispatch<React.SetStateAction<boolean>>
  totalSumProducts: number
}

export function ModalInstacash ({ setIsOpenModalInstacash, seIisOpenModalSelectPaymentMethod, totalSumProducts }: Props) {
  const [isModalSuccess, setIsModalSuccess] = useState(false)
  const [isModalError, setIsModalError] = useState(false)

  const handlePayment = () => {
    const result = true
    if (result) {
      setIsModalSuccess(true)
      setIsModalError(false)
    } else {
      setIsModalSuccess(false)
      setIsModalError(true)
    }
  }

  const handleContinue = () => {
    setIsModalSuccess(false)
    setIsOpenModalInstacash(false)
    seIisOpenModalSelectPaymentMethod(false)
  }

  const handleOtherPayment = () => {
    setIsModalSuccess(false)
    setIsOpenModalInstacash(false)
  }

  const handleBack = () => {
    setIsModalError(false)
    setIsOpenModalInstacash(false)
    seIisOpenModalSelectPaymentMethod(false)
  }

  const handleTryAgain = () => {
    setIsModalError(false)
    setIsOpenModalInstacash(false)
  }

  return (
    <>
      <div style={{ maxWidth: '600px' }}>
        <div className='flex ion-justify-content-between ion-align-items-center' style={{ gap: '1rem' }}>
          <IonItem button={true} detail={false} lines='none'>
            <IonLabel>
              <h2>Enviar pago</h2>
              <p className='ion-text-wrap'>Realizar un pago desde e4Cash es muy fácil. Comparte tus instrucciones a continuación y haremos la transacción al instante.</p>
            </IonLabel>
          </IonItem>
          <CountDown />
        </div>
        <LineSeparator noSpaceTop />
        <div className='flex ion-justify-content-center ion-align-items-center ion-padding' >
          <IonIcon icon={sync} style={{ fontSize: '92px' }} color='secondary' />
        </div>
        <IonItem button={false} detail={false} disabled={false} lines='none' className="input-item">
          <IonLabel>
            <h2>id de la cuenta</h2>
            <p>7f41a26a17972cca29080eaf7e58c488a76c7250aae51465fae26v8cd740a91</p>
          </IonLabel>
          <IonIcon icon={copy} slot="end"></IonIcon>
        </IonItem>
        <IonItem button={false} detail={false} disabled={false} lines='none' className="input-item">
          <IonLabel>
            <h2>Monto total</h2>
            <p>${totalSumProducts.toFixed(2)}</p>
          </IonLabel>
        </IonItem>
        <div className="ion-text-center ion-padding-top ion-padding-bottom" style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
          <IonButton color="secondary" shape="round" onClick={() => { setIsOpenModalInstacash(false) }} fill="outline">Volver</IonButton>
          <IonButton color="secondary" shape="round" onClick={() => { handlePayment() }}>Cobrar</IonButton>
        </div>
      </div>
     { isModalSuccess && <ModalPaymentSuccess
        isModalSuccess={isModalSuccess}
        setIsModalSuccess={setIsModalSuccess}
        handleContinue={handleContinue}
        handleOtherPayment={handleOtherPayment}
      />}
     {isModalError && <ModalPaymentError
        isModalError={isModalError}
        setIsModalError={setIsModalError}
        handleBack={handleBack}
        handleTryAgain={handleTryAgain}
      />}
    </>
  )
}
