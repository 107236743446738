import { IonIcon, IonLabel, IonTabBar, IonTabButton } from '@ionic/react'
import { calculator, list, personCircle } from 'ionicons/icons'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { changeCurrentTab, toggleShowCalculator } from '../../../../store/states/pos'

export function FooterPos () {
  const dispatch = useAppDispatch()
  const posState = useAppSelector(store => store.pos)

  const handleCurrentTab = (e: any) => {
    if (e.detail.tab === 'calculator') {
      dispatch(changeCurrentTab(posState.tab))
      dispatch(toggleShowCalculator(!posState.showCalculator))
      return
    }
    dispatch(changeCurrentTab(e.detail.tab))
  }

  return (
    <IonTabBar slot="bottom" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', justifyItems: 'center' }} onIonTabsWillChange={e => { handleCurrentTab(e) }}>
      <IonTabButton tab="accountOne" selected={Boolean(posState.tab === 'accountOne')}>
        <IonIcon icon={personCircle} aria-hidden="true" />
        <IonLabel>Cuenta 1</IonLabel>
      </IonTabButton>

      <IonTabButton tab="accountTwo" selected={Boolean(posState.tab === 'accountTwo')}>
        <IonIcon icon={personCircle} aria-hidden="true" />
        <IonLabel>Cuenta 2</IonLabel>
      </IonTabButton>

      <IonTabButton tab="accountThree" selected={Boolean(posState.tab === 'accountThree')}>
        <IonIcon icon={personCircle} aria-hidden="true" />
        <IonLabel>Cuenta 3</IonLabel>
      </IonTabButton>

      <IonTabButton tab="library" selected={Boolean(posState.tab === 'library')}>
        <IonIcon icon={list} aria-hidden="true" />
        <IonLabel>Library</IonLabel>
      </IonTabButton>

      <IonTabButton tab="calculator" selected={Boolean(posState.showCalculator)}>
        <IonIcon icon={calculator} aria-hidden="true" />
        <IonLabel>Calculadora</IonLabel>
      </IonTabButton>
    </IonTabBar>
  )
}
