import { IonCard, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { addCircle, closeCircleOutline, removeCircle } from 'ionicons/icons'
import type { ProductCart } from '../../Pos'
import { useAppDispatch } from '../../../../store/hooks'
import { decrementProductInCart, deleteProductToCart, incrementProductInCart } from '../../../../store/states/pos'

interface Props {
  productsCart: ProductCart[]
}

export function ListProductCartPos ({ productsCart }: Props) {
  const dispatch = useAppDispatch()

  const handleIncrementProductQuantity = (product: ProductCart) => {
    dispatch(incrementProductInCart({ productToIncrement: product }))
  }

  const handleDecrementProductQuantity = (product: ProductCart) => {
    dispatch(decrementProductInCart({ productToDecrement: product }))
  }

  const handleDeleteProductToCart = (product: ProductCart) => {
    dispatch(deleteProductToCart({ productToDelete: product }))
  }

  return (
    <IonCard className='ion-padding'>
      {
        productsCart?.length < 1
          ? <IonLabel className="ion-text-center"><h3 style={{ marginRight: '15px' }}>No hay productos agregados</h3></IonLabel>
          : <IonList>
            {
              productsCart?.map(product =>
                <IonItem button={true} detail={false} lines='full' key={product.id}>
                  <IonLabel >
                    <div className='flex'>
                      <h3 style={{ marginRight: '15px' }}>{product.quantity}</h3>
                      <div>
                        <h2>{product.title}</h2>
                        <p>Código {product.code}</p>
                      </div>
                    </div>
                  </IonLabel>
                  <IonLabel >
                    <h2>${product.price}</h2>
                    <p>Sin descuento</p>
                  </IonLabel>
                  <IonIcon icon={addCircle} size="large" slot='end' onClick={() => { handleIncrementProductQuantity(product) }} />
                  <IonIcon icon={removeCircle} size="large" slot='end' onClick={() => { handleDecrementProductQuantity(product) }} />
                  <IonIcon icon={closeCircleOutline} size="large" slot='end' onClick={() => { handleDeleteProductToCart(product) }} />
                </IonItem>
              )
            }
          </IonList>
      }
    </IonCard>
  )
}
