import { type InputChangeEventDetail, IonInput, IonItem, IonLabel } from '@ionic/react'
import React, { type FocusEventHandler } from 'react'
import './Input.css'

interface InputProps {
  label: string
  type: any
  name: string
  error?: boolean
  errorMessage?: JSX.Element
  step?: string
  value?: null | number | string | undefined
  onChange?: ((event: CustomEvent<InputChangeEventDetail>) => void) | undefined
  disabled?: boolean
  noSpace?: boolean
  onBlur?: FocusEventHandler<HTMLIonInputElement>
}

export const Input: React.FC<InputProps> = ({ label, type, name, value, error, onChange, disabled, onBlur, step, errorMessage, noSpace = false }) => {
  return (
    <>
    <IonItem className={`input-item ${error && 'error'} ${noSpace && 'no-space'}`}>
      <IonLabel position="floating">{ label }</IonLabel>
      <IonInput
        type={type}
        onBlur={onBlur}
        step={step as string}
        name={name}
        value={value}
        disabled={disabled}
        onIonChange={onChange}
      />
    </IonItem>
    {error && errorMessage}
    </>
  )
}
