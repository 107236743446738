import { IonButton, IonButtons, IonCard, IonItem, IonLabel, IonMenuButton, useIonToast } from '@ionic/react'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { HeaderDashboard } from '../Dashboard/components/HeaderDashboard'

export function Complements () {
  const [present] = useIonToast()

  const message = () => {
    present({
      message: 'Modulo aún no disponible',
      duration: 2500,
      position: 'bottom',
      color: 'warning'
    })
  }
  return (
    <>
      <WrapperApp
        pageName="Complementos"
        hiddenAvatar={true}
        renderButtons={() => (
          <>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <HeaderDashboard />
          </>
        )}
      >
        <IonCard className='box ion-padding' style={{ minHeight: '400px' }}>
          <IonItem button={false} detail={false} lines='full'>
            <IonLabel slot='start' className='ion-no-padding'>
              <p className='ion-no-padding'>Nombre</p>
            </IonLabel>
            <IonLabel slot='end' className='ion-no-padding'>
              <p className='ion-no-padding'>Saldo</p>
            </IonLabel>
          </IonItem>
          <div className="ion-text-end ion-margin-top">
            <IonButton color="secondary" shape="round" type="submit" onClick={() => { message() }}>
              Instalar
            </IonButton>
          </div>
        </IonCard>
      </WrapperApp>
    </>
  )
}
