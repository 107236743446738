import { IonIcon, IonText, IonItem, IonLabel, IonButton, IonCard } from '@ionic/react'
import { checkmarkCircle } from 'ionicons/icons'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'

interface Props {
  isModalSuccess: boolean
  setIsModalSuccess: React.Dispatch<React.SetStateAction<boolean>>
  handleContinue: () => void
  handleOtherPayment: () => void
}

export function ModalPaymentSuccess ({ isModalSuccess, setIsModalSuccess, handleContinue, handleOtherPayment }: Props) {
  return (
    <IonCard>
      <ModalSmall isOpen={isModalSuccess} onDidDismiss={() => { setIsModalSuccess(false) }}>
        <div style={{ width: '100%', minWidth: '500px', maxWidth: '500px' }}>
          <div className='flex ion-justify-content-center ion-align-items-center ion-padding' >
            <IonIcon icon={checkmarkCircle} style={{ fontSize: '92px' }} color='secondary' />
          </div>
          <div className='ion-text-center'>
            <TitleForm title="¡Felicidades!" customClass="ion-no-padding ion-no-margin ion-text-center" />
            <IonText color='dark'><p className='ion-text-center'>La compra se ha realizado exitosamente</p></IonText>
          </div>
          <TitleForm title="Solicitud completada" customClass="ion-padding-start ion-no-margin ion-text-start ion-padding-bottom" />
          <IonItem lines='none'>
            <IonLabel slot='start'>
              <h3 className="ion-no-padding ion-no-margin" style={{ fontWeight: 'bold' }}>Nro de solicitud: <span style={{ fontWeight: '300' }}> 32</span></h3>
            </IonLabel>
          </IonItem>
          <IonItem lines='none'>
            <IonLabel slot='start'>
              <h3 className="ion-no-padding ion-no-margin" style={{ fontWeight: 'bold' }}>Fecha de solicitud: <span style={{ fontWeight: '300' }}> 24/10/2022</span></h3>
            </IonLabel>
          </IonItem>
          <IonItem lines='none'>
            <IonLabel slot='start'>
              <h3 className="ion-no-padding ion-no-margin" style={{ fontWeight: 'bold' }}>Monto: <span style={{ fontWeight: '300' }}> 24/10/346,88</span></h3>
            </IonLabel>
          </IonItem>
          <IonItem lines='none'>
            <IonLabel slot='start'>
              <h3 className="ion-no-padding ion-no-margin" style={{ fontWeight: 'bold' }}>Estatus de depósito: <span style={{ fontWeight: '300' }}> Aprobada</span></h3>
            </IonLabel>
          </IonItem>
          <div className="ion-text-center ion-padding-top ion-padding-bottom" style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
            <IonButton color="secondary" shape="round" onClick={() => { handleOtherPayment() }}>Realizar otro pago</IonButton>
            <IonButton color="secondary" shape="round" fill="outline" onClick={() => { handleContinue() }}>Continuar</IonButton>
          </div>
        </div>
      </ModalSmall>
    </IonCard>
  )
}
