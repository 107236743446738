import { useEffect, useState } from 'react'
import './CountDown.css'
import { IonText } from '@ionic/react'

let timeout: NodeJS.Timeout

const COUNTDOWN_AMOUNT_TOTAL = 1 * 60
export function CountDown () {
  const [seconds, setSeconds] = useState<number>(COUNTDOWN_AMOUNT_TOTAL)

  const displaySeconds = seconds % 60
  const displayMinutes = Math.floor(seconds / 60)

  const finishCount = () => {
    console.log('¿handle finihs')
  }
  useEffect(() => {
    if (seconds > 0) {
      timeout = setTimeout(() => {
        setSeconds((state) => state - 1)
      }, 1000)
    } else {
      finishCount()
      clearTimeout(timeout)
    }
  }, [seconds])

  return (
    <div className='container-count'>
      <IonText color='dark'>{displayMinutes}</IonText>
      <IonText color='dark'>:</IonText>
      <IonText color='dark'>{displaySeconds}</IonText>
    </div>
  )
}
