import { IonButton, IonCard, IonIcon, IonText } from '@ionic/react'
import { alertCircle } from 'ionicons/icons'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'

interface Props {
  isModalError: boolean
  setIsModalError: React.Dispatch<React.SetStateAction<boolean>>
  handleBack: () => void
  handleTryAgain: () => void
}

export function ModalPaymentError ({ isModalError, setIsModalError, handleBack, handleTryAgain }: Props) {
  return (
    <IonCard className='card'>
      <ModalSmall isOpen={isModalError} onDidDismiss={() => { setIsModalError(false) }} customClass='card'>
        <div style={{ width: '100%', minWidth: '500px', maxWidth: '500px' }}>
          <div className='flex ion-justify-content-center ion-align-items-center ion-padding' >
            <IonIcon icon={alertCircle} style={{ fontSize: '92px' }} color='danger' />
          </div>
          <div className='ion-text-center'>
            <TitleForm title="Ha ocurrido un error" customClass="ion-no-padding ion-no-margin ion-text-center" />
            <IonText color='dark'><p className='ion-text-center'>No se ha podido realizar el pago.</p></IonText>
          </div>
          <div className="ion-text-center ion-padding-top ion-padding-bottom" style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
            <IonButton color="secondary" shape="round" fill="outline" onClick={() => { handleBack() }}>Volver</IonButton>
            <IonButton color="secondary" shape="round" onClick={() => { handleTryAgain() }}>Intentar de nuevo</IonButton>
          </div>
        </div>
      </ModalSmall>
    </IonCard>
  )
}
