import { IonItem, IonLabel, IonSelect, IonSelectOption, IonButton, IonList } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../../components/Atoms/Input/Input'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'

interface UserDashboard {
  id: number
  name: string
  username: string
}

export function SectionSelectCustomer () {
  const [openModalClients, setOpenModalClients] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [userSelectedToAdd, setUserSelectedToAdd] = useState<UserDashboard | null>(null)
  const [listOfUserAdded, setListOfUserAdded] = useState<UserDashboard[]>([])

  const USERS: UserDashboard[] = [
    {
      id: 1,
      name: 'nelson urbaneja',
      username: 'neslon'
    },
    {
      id: 2,
      name: 'juan perez',
      username: 'juan'
    },
    {
      id: 3,
      name: 'pedro gonzales',
      username: 'pedro'
    }
  ]

  const handleSelectUser = (user: UserDashboard) => {
    setUserSelectedToAdd(user)
    setListOfUserAdded(prevUsers => {
      return [...prevUsers, user]
    })
	  setOpenModalClients(false)
  }

  const filteredUser = USERS.filter(user => {
    return searchValue
		  ? user.username.toLowerCase().includes(searchValue.toLowerCase())
		  : USERS
  })

  return (
		<div className="ion-margin-bottom">
			<IonItem className="input-item ion-no-margin" disabled={!userSelectedToAdd}>
				<IonLabel position="floating">Customer</IonLabel>
				<IonSelect
					value={userSelectedToAdd?.username}
					placeholder="Customer"
					name="customer"
				>
					{listOfUserAdded.map(user =>
						<IonSelectOption key={user.id} value={user?.username}>${user?.username}</IonSelectOption>
					)}
				</IonSelect>
			</IonItem>
			<IonButton color="secondary" expand="block" onClick={() => { setOpenModalClients(true) }}>Agregar cliente</IonButton>
			<ModalSmall isOpen={openModalClients} onDidDismiss={() => { setOpenModalClients(false) }}>
        <div style={{ minWidth: '100%' }}>
					<IonList>
						<IonItem className="ion-no-padding">
							<Input label="Customer" type="search" name="firstName" value={searchValue} onChange={(e: any) => { setSearchValue(e?.target.value) } }/>
						</IonItem>
						{filteredUser.length > 0
						  ? filteredUser.map(user => {
						  const isUserAdded = listOfUserAdded.some(userItem => userItem.id === user.id)

						  return (
								<IonItem button key={user.id} onClick={() => { handleSelectUser(user) }} detail={!isUserAdded} disabled={isUserAdded}>
									<IonLabel>${user.username} - { user.name } { isUserAdded && '(agregado)'} </IonLabel>
								</IonItem>
						  )
						  })
						  : <IonItem>
								<IonLabel>Sin resultados</IonLabel>
							</IonItem>
						}
          </IonList>
        </div>
      </ModalSmall>
		</div>
  )
}
