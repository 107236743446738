import WrapperFormBackground from '../../components/Organisms/WrapperFormBackground/WrapperFormBackground'
import { FormRegister } from './components/FormRegister/FormRegister'

export const Register = () => {
  return (
    <WrapperFormBackground title="Creación de usuario">
       <FormRegister />
    </WrapperFormBackground>
  )
}
