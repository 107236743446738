import { IonCard, IonGrid, IonRow, IonCol, IonText, IonButton, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { editFullName } from '../../../../store/states/user'

const FormEditFullName: React.FC = () => {
  const [present] = useIonToast()
  const history = useHistory()
  const user = useAppSelector(store => store.user)
  const dispatch = useAppDispatch()
  const [editData, setEditData] = useState({
    firstName: user.firstName,
    lastName: user.lastName
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // run services edit here!
    present({
      message: 'Datos guardados',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
    dispatch(editFullName(editData))
    history.replace('/perfil', { replace: true })
  }

  const handleChange = (e: any) => {
    console.log({ e })
    setEditData(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }

  return (
		<IonCard className="box ion-padding">
			<form onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title="Nombre completo" customClass="ion-margin-start" />
				<IonGrid>
          <IonRow>
            <IonCol>
              <Input label="Nombre" type="text" name="firstName" value={editData.firstName} onChange={e => { handleChange(e) }}/>
            </IonCol>
            <IonCol>
							<Input label="Apellido" type="text" name="lastName" value={editData.lastName} onChange={e => { handleChange(e) }} />
            </IonCol>
          </IonRow>
        </IonGrid>
				<div className="ion-padding">
					<IonText>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</IonText>
				</div>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">Guardar</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { history.replace('/perfil', { replace: true }) }}>Cancelar</IonButton>
				</div>
			</form>
		</IonCard>
  )
}

export default FormEditFullName
