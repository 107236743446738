import { IonButton, IonCard, IonTitle } from '@ionic/react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { ModalSelectPaymentMethod } from '../ModalSelectPaymentMethod/ModalSelectPaymentMethod'
import { useMemo, useState } from 'react'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import type { ProductCart } from '../../Pos'

interface Props {
  productsCart: ProductCart[]
}

export function CartTotal ({ productsCart }: Props) {
  const [isOpenModalSelectPaymentMethod, seIisOpenModalSelectPaymentMethod] = useState(false)

  const totalSumProducts = useMemo(() => {
    return productsCart.reduce((accumulator, currentValue) => {
      return accumulator + (Number(currentValue.quantity) * currentValue.price)
    }, 0)
  }, [productsCart])

  return (
    <>
      <IonCard className="ion-padding">
        <TitleForm title='Pago total' />
        <div className='flex ion-justify-content-between ion-align-items-center'>
          <IonTitle className="ion-text-letf ion-no-padding">R$ { totalSumProducts?.toFixed(2) }</IonTitle>
          <IonButton color="secondary" shape="round" onClick={() => { seIisOpenModalSelectPaymentMethod(true) }}>Cobrar</IonButton>
        </div>
      </IonCard>
      <ModalSmall isOpen={isOpenModalSelectPaymentMethod} onDidDismiss={() => { seIisOpenModalSelectPaymentMethod(false) }}>
        <ModalSelectPaymentMethod seIisOpenModalSelectPaymentMethod={seIisOpenModalSelectPaymentMethod} totalSumProducts={totalSumProducts} />
      </ModalSmall>
    </>
  )
}
